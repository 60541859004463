<template>
  <div class="help-menu">
    <v-menu
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>fa fa-question-circle</v-icon>
        </v-btn>
      </template>

      <v-list
        style="min-width: 200px"
        class="pa-4"
        dense
      >
        <div
          v-for="(list, l) in lists"
          :key="l"
          class="pb-2"
        >
          <v-list-item-title
            v-if="list.title"
            class="label"
            style="color: #a2a2a2; font-size: 14px;"
          >
            {{ list.title }}
          </v-list-item-title>
          <v-list
            v-for="(item, i) in list.items"
            :key="i"
            class="pa-0"
            dense
          >
            <v-list-item
              class="pa-0"
              style="font-size: 16px;"
              v-bind="item.props"
              v-on="item.on">{{ item.text }}
            </v-list-item>
          </v-list>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { Account } from 'ui/models'

const targetedLinks = {
  Home: 'https://22400538.hs-sites.com/patch-help-center/dashboards-home-overview',
  DashboardRetention: 'https://22400538.hs-sites.com/patch-help-center/dashboards-retention-overview',
  DashboardMarketing: 'https://22400538.hs-sites.com/patch-help-center/dashboards-marketing-overview',
  DashboardTools: 'https://22400538.hs-sites.com/patch-help-center/dashboards-tools-overview',
  Messenger: 'https://22400538.hs-sites.com/patch-help-center/kb-search-results?term=messenger',
  AutoReplies: 'https://22400538.hs-sites.com/patch-help-center/auto-replies',
  LoyaltyCash: 'https://22400538.hs-sites.com/patch-help-center/loyalty-rewards-overview',
  TextChat: 'https://22400538.hs-sites.com/patch-help-center/textchat-widget-overview',
  Reports: 'https://22400538.hs-sites.com/patch-help-center/reports-overview',
  Settings: 'https://22400538.hs-sites.com/patch-help-center/kb-search-results?term=settings',
  ShopifySettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-shopify',
  LoyaltySettings: 'https://22400538.hs-sites.com/patch-help-center/loyalty-settings',
  AccountSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-account-details',
  EmailSnippetSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-email-snippets',
  // 'Account Settings - Add Email Domain to Account': 'https://22400538.hs-sites.com/patch-help-center/account-settings-email-settings',
  TemplateSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-templates',
  WebPushSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-web-push-notifications',
  SmsSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-sms-settings',
  RfmSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-rfm-recency-frequency-monetary',
  ProfileAccount: 'https://22400538.hs-sites.com/patch-help-center/account-settings-personal-profile',
  UsageSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-message-usage',
  ImportSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-imports',
  GroupSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-groups',
  GeneralFieldSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-general-fields-account-level',
  FileSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-file-manager',
  FieldSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-fields-contact-level',
  EmailSettings: 'hhttps://22400538.hs-sites.com/patch-help-center/account-settings-email-settings',
  BillingSettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-billing-overview',
  APISettings: 'https://22400538.hs-sites.com/patch-help-center/account-settings-api-access',
  'Adding Admin Users': 'https://22400538.hs-sites.com/patch-help-center/adding-admins-/-setting-up-limited-admins',
  // Waivers: '',
  Automations: 'https://22400538.hs-sites.com/patch-help-center/automation-performance-stats-customer-journeys',
  Blasts: 'https://22400538.hs-sites.com/patch-help-center/sms-email-blasts-overview',
  Contacts: 'https://22400538.hs-sites.com/patch-help-center/contacts-main-view',
  // 'Kiosk Device List': '',
  // Kiosk: '',
  Offers: 'https://22400538.hs-sites.com/patch-help-center/smartoffers-main-view',
  Smartlinks: 'https://22400538.hs-sites.com/patch-help-center/smartlinks',
  AutomationEditor: 'https://22400538.hs-sites.com/patch-help-center/automation-wizard-customer-journeys',
  BlastEditor: 'https://22400538.hs-sites.com/patch-help-center/sms-email-blasts-overview',
  'Email Editor': 'https://22400538.hs-sites.com/patch-help-center/email-editor-overview',
  'SMS Editor': 'https://22400538.hs-sites.com/patch-help-center/sms-content-editor-overview',
  // KioskFlowEditor: ''
  Forms: 'https://22400538.hs-sites.com/patch-help-center/patch-forms-overview',
  FormsAutomaticInstallation: 'https://22400538.hs-sites.com/patch-help-center/forms-automatic-installation'
}

export default {
  props: {
    path: {
      type: String,
      required: false
    },
    propLabel: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(['account']),
    showWelcomeDialogs () {
      return this.account?.settings?.show_welcome_dialogs || false
    },
    lists () {
      const lists = {
        help: {
          title: 'Help',
          items: []
        },
        contact: {
          title: 'Contact',
          items: []
        },
        preferences: {
          title: '',
          items: []
        }
      }

      // Help list
      lists.help.items.push({
        text: 'Help Center',
        props: {
          href: 'https://22400538.hs-sites.com/patch-help-center',
          target: '_blank'
        }
      })
      if (this.helpCenterLink) {
        lists.help.items.push({
          text: 'About ' + this.label,
          props: {
            href: this.helpCenterLink,
            target: '_blank'
          }
        })
        // If the user needs information about fonts
        if (
          this.label === 'Template Settings' ||
          this.label === 'Blasts' ||
          this.label === 'Automations' ||
          this.label === 'Email Snippets') {
          lists.help.items.push({
            text: 'Fonts best practices',
            props: {
              href: 'https://22400538.hs-sites.com/patch-help-center/email-editor-fonts-fallback-fonts',
              target: '_blank'
            }
          })
        }
      }
      lists.help.items.push({
        text: 'Support Form',
        props: {
          href: 'https://22400538.hs-sites.com/patch-help-center/kb-tickets/new',
          target: '_blank'
        }
      })
      lists.help.items.push({
        text: 'YouTube Videos',
        props: {
          href: 'https://www.youtube.com/channel/UCO_QnoHGhWB3lOKGRJn7CgQ/videos',
          target: '_blank'
        }
      })

      // Contact support list
      const isTrialAccount = this.$restrict({ 'addons.type': 'BUNDLE-PATCH-TRIAL' })
      if (isTrialAccount) {
        lists.contact.items.push({
          text: 'Schedule a Demo',
          props: {
            href: 'https://calendly.com/patchretention/patch-questions',
            target: '_blank'
          }
        })
      } else {
        lists.contact.items.push({
          text: 'Chat with a Success Agent',
          on: {
            click: this.openTextChat
          },
          props: {
            tag: 'button'
          }
        })
      }

      const showPreferences = this.$restrict({ 'addons.type': { $in: ['BUNDLE-PATCH-TRIAL', 'BUNDLE-PATCH-FULL'] } })
      if (showPreferences) {
        // Preferences list
        lists.preferences.title = 'Preferences'
        lists.preferences.items.push({
          text: this.showWelcomeDialogs ? 'Hide Welcome Dialogs' : 'Show Welcome Dialogs',
          on: {
            click: this.toggleWelcomeDialogs
          },
          props: {
            tag: 'button'
          }
        })
      }

      return lists
    },
    primaryKey () {
      return this.path || this.$route?.name || this.$route?.meta?.name
    },
    helpCenterLink () {
      return targetedLinks[this.primaryKey]
    },
    label () {
      return (
        this.propLabel ||
        this.$route?.meta?.label ||
        this.$route?.label ||
        this.$route?.meta?.name ||
        this.$route?.name
      )
    }
  },
  methods: {
    ...mapActions({
      openTextChat: 'widgets/openTextChat'
    }),
    async toggleWelcomeDialogs () {
      await Account.save({
        ...this.account,
        settings: {
          ...this.account.settings,
          show_welcome_dialogs: !this.account?.settings?.show_welcome_dialogs
        }
      })
    }
  }
}
</script>
