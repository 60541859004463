import fromPairs from 'lodash/fromPairs'

// The fields returned for this event type depend on existing contact fields as well as
// add-ons the account has.

// This event contains both previous and current values for every changed field, which
// creates a dependency on contact schemas as well as add-ons (for client_id)

export default ({ schemas = {}, addons = [] }) => {
  const contactSchemas = Object.entries(schemas)
    .filter(([key]) => key.startsWith('contact.'))

  return {
    from: { name: 'Previous Field Values', type: 'object', unselectable: true },
    ...fromPairs(
      contactSchemas.map(([key, value]) => ['from.' + key.replace('contact.', ''), {
        ...value,
        name: value.name
      }])
    ),
    to: { name: 'Final Field Values', type: 'object', unselectable: true },
    ...fromPairs(
      contactSchemas.map(([key, value]) => ['to.' + key.replace('contact.', ''), {
        ...value,
        name: value.name
      }])
    ),
    client_id: {
      name: 'Client ID',
      type: 'text'
      // options: [
      //   { label: 'Portal', value: 'PORTAL' },
      //   { label: 'API', value: 'API' },
      //   { label: 'Waiver', value: 'DOCUMENT' },
      //   { label: 'Kiosk', value: 'KIOSK' },
      //   { label: 'Form', value: 'WEBFORM' },
      //   { label: 'TextChat Widget', value: 'TEXTCHAT-WIDGET' },
      //   { label: 'NCR Silver', value: 'NCRSILVER' },
      //   { label: 'Shopify', value: 'SHOPIFY' },
      //   { label: 'Loyalty Cash Widget', value: 'LOYALTYCASH' }
      // ]
    }
  }
}
