<template>
  <div>
    <v-card>
      <div
        v-if="loading"
        class="d-flex align-center justify-center py-4"
        style="height: 100%"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
      <v-simple-table
        v-else-if="minorContacts.length"
        dense
      >
        <thead>
        <tr>
          <th>
            <v-btn
              @click="loadData({ refresh: true })"
              :disabled="loading"
              icon
              small
            >
              <v-icon small>fa-redo</v-icon>
            </v-btn>
          </th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Name</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Birthday</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="minorContact in minorContacts"
          :key="minorContact._id"
        >
          <td class="d-flex align-center justify-start">
            <v-tooltip
              top
              max-width="300"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  target="_blank"
                  icon
                  small
                  @click="openContactEditor(minorContact)"
                >
                  <v-icon small>fa-solid fa-user</v-icon>
                </v-btn>
              </template>
              <div>View Contact</div>
            </v-tooltip>
          </td>
          <td>{{ minorContact.friendlyName }}</td>
          <td>{{ minorContact.birthdayFormatted }}</td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-text v-else>
        <v-btn
          @click="loadData()"
          :disabled="loading"
          icon
          small
        >
          <v-icon small>fa-redo</v-icon>
        </v-btn>
        <span class="ml-2">No minors have been linked to this contact.</span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ContactEditor from '../'
import { Contact } from 'ui/models'
import { getContactFriendlyName } from 'shared/util/friendlyName'
import { fieldValue } from 'shared/field'
import ContactRef from 'ui/references/Contact'

export default {
  mixins: [
    Contact.mixin({ references: ['contact', 'minorContacts'] })
  ],
  component: {
    ContactRef
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      minorContactIds: []
    }
  },
  computed: {
    contact () {
      return Contact.getFlattened(this.contactId)
    },
    minorContacts () {
      return this.minorContactIds.map(id => {
        const contact = Contact.getFlattened(id)
        return {
          ...contact,
          friendlyName: getContactFriendlyName(contact),
          birthdayFormatted: fieldValue(
            contact.birthday,
            { type: 'date' }
          )
        }
      })
    }
  },
  async mounted () {
    await this.loadData()
  },
  methods: {
    async loadData () {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const response = await Contact.fetchMany({
          params: {
            filter: JSON.stringify({ where: ['AND', ['contact._guardian_contact_id', '=', this.contactId]] }),
            limit: 50
          }
        })
        this.minorContactIds = (response.entities?.contacts || []).map(record => record._id)
        this.loading = false
      } catch (e) {
        this.loading = false
        throw e
      }
    },
    async openContactEditor (contact) {
      this.$openWindow({
        component: ContactEditor,
        props: {
          contactId: contact._id
        },
        oWindow: {
          offsetTop: 20
        }
      })
    }
  }
}
</script>
