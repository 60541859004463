<template>
  <v-card>
    <div
      class="pl-3 pr-3 pt-3 pb-0 d-flex"
    >
      <div
        class="flex-grow-1"
      >
        <p><strong>{{ title || 'Events'}}</strong></p>
      </div>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="$emit('load')"
              :disabled="loading"
              icon
              small
            >
              <v-icon small>fa-redo</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </div>
    </div>
    <div
      v-if="loading && !events.length"
      class="d-flex align-center justify-center py-4"
      style="height: 100%"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </div>
    <v-simple-table
      v-else-if="events.length"
      dense
    >
      <thead>
      <tr>
        <th></th>
        <th
          class="text-uppercase"
          :style="`color: ${$vuetify.theme.currentTheme.primary}`"
        >Name</th>
        <th
          class="text-uppercase"
          :style="`color: ${$vuetify.theme.currentTheme.primary}`"
        >Start Time</th>
        <th
          class="text-uppercase"
          :style="`color: ${$vuetify.theme.currentTheme.primary}`"
        >Duration</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="event in events"
        :key="event._id"
      >
        <td
          class="pa-2"
        >
          <div style="display: inline-block; white-space: nowrap;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loading"
                  icon
                  small
                  @click="$emit('edit', event)"
                >
                  <v-icon small>fa-{{ event.imported ? 'eye' : 'edit' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ event.imported ? 'View (imported event)' : 'Edit' }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="!event.imported"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loading"
                  icon
                  small
                  @click="$emit('destroy', event)"
                >
                  <v-icon small>fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
        </td>
        <td>{{ event.title || '' }}</td>
        <td><ui-timestamp :value="event.start_at" format="MMM D, YYYY h:mm A" /></td>
        <td>{{ event.duration || '' }}</td>
      </tr>
      </tbody>
    </v-simple-table>
    <v-card-text
      v-else
    >
      <v-btn
        @click="$emit('load')"
        :disabled="loading"
        icon
        small
      >
        <v-icon small>fa-redo</v-icon>
      </v-btn>
      <span class="ml-2">No events.</span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    events: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
