import BaseField from './_BaseField'
import toString from 'lodash/toString'
import capitalize from 'lodash/capitalize'
import tagCase from 'shared/util/tagCase'

class Text extends BaseField {
  static sanitize ({ value, schema, options }) {
    if (['number', 'string', 'boolean'].includes(typeof value)) {
      value = toString(value)
    }
    if (typeof value === 'string' && value) {
      if (!options?.skipRegexReplace) {
        value = runReplaces({ value, schema, options })
      }
      if (!options?.skipDataMaskReplace) {
        value = runDataMask({ value, schema, options })
      }
      return value.substr(0, 2000) // 2k max for a text field
    }
    return null
  }

  static unsanitize ({ value, schema }) {
    return toString(value)
  }
}

const runReplaces = ({ value, schema = {} }) => {
  if (!value) {
    return value
  }
  if (schema?.replace && Array.isArray(schema.replace)) {
    schema.replace.forEach((replace) => {
      if (replace.case) {
        if (replace.case === 'lower') {
          value = value.toLowerCase()
        } else if (replace.case === 'upper') {
          value = value.toUpperCase()
        } else if (replace.case === 'title') {
          value = (value + '').toLowerCase().replace(/\w+/g, capitalize)
        } else if (replace.case === 'tag') {
          value = tagCase(value)
        }
      } else if (replace.pattern && (typeof replace.replacement === 'string' || typeof replace.replacement === 'number')) {
        value = value.replace(new RegExp(replace.pattern, 'g'), replace.replacement)
      }
      if (replace.trim) {
        value = (value + '').trim()
      }
    })
  }
  return value
}

export const runDataMask = ({ value, schema = {} }) => {
  if (!value) {
    return value
  }
  if (schema?.data_mask) {
    value = value + ''
    if (value.length < schema.data_mask.length) {
      const mask = schema.data_mask
      let number = value
      let final = ''
      for (let i = 0; i < mask.length; i++) {
        if (mask[i] !== '_') {
          final += mask[i]
        } else if (number.length > 0) {
          final = final + number.substr(0, 1)
          number = number.substr(1)
        }
      }
      value = final
    }
  }
  return value
}

export default Text
