import Model from './Model'

class FlowPage extends Model {
  static entity = 'flow_pages'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      type: this.attr(undefined),
      title: this.attr(undefined),
      fields: this.attr(undefined),
      filter: this.attr(undefined),
      hide_top_bar: this.attr(undefined),
      hide_next_button: this.attr(undefined),
      hide_back_button: this.attr(undefined),
      default_contact: this.attr(undefined),
      loading_page: this.attr(undefined),
      sync: this.attr(undefined),
      colors_rgba: this.attr(undefined),
      background_images: this.attr(undefined),
      background_timeout: this.attr(undefined),
      content_top: this.attr(undefined),
      content: this.attr(undefined),
      content_bottom: this.attr(undefined),
      back_button: this.attr(undefined),
      next_button: this.attr(undefined),
      timeout: this.attr(undefined),
      inactive: this.attr(undefined),
      created_at: this.attr(undefined),
      created_by: this.attr(undefined),
      updated_at: this.attr(undefined),
      updated_by: this.attr(undefined),
      permissions: this.attr(undefined),
      security_codes: this.attr(undefined),
      security_code_next: this.attr(undefined),

      // TODO: Remove `colors` when K3 is dead, as these are stored in ARGB,
      //  and we don't want people getting confused and thinking it's useful.
      colors: this.attr(undefined),
      channel: this.attr(undefined), // What's this for?

      // OFFERS PAGE
      offers: this.attr(undefined),
      restrict_offers: this.attr(undefined),
      restrict_offers_to_account: this.attr(undefined),
      coupon_code_image: this.attr(undefined),
      default_image_header: this.attr(undefined),
      default_image_background: this.attr(undefined),
      point_modification: this.attr(undefined),
      security_code_threshold: this.attr(undefined),
      checkin_interval: this.attr(undefined), // deprecated in favor of punchcards[]
      points_per_checkin: this.attr(undefined), // deprecated in favor of punchcards[]
      punchcard_key: this.attr(undefined), // deprecated in favor of punchcards[]
      punchcards: this.attr(undefined),
      empty_message: this.attr(undefined),
      // WELCOME PAGE
      terms_url: this.attr(undefined),
      terms_text: this.attr(undefined),
      hide_terms: this.attr(undefined),
      checkin_button_text: this.attr(undefined),
      // SELECTION PAGE
      columns: this.attr(undefined),
      buttons: this.attr(undefined),
      use_saved_values: this.attr(undefined),
      min_selected: this.attr(undefined),
      max_selected: this.attr(undefined),
      auto_progress: this.attr(undefined),
      // VIDEO PAGE
      filename: this.attr(undefined),
      hide_next: this.attr(undefined),
      hide_buttons: this.attr(undefined),
      autonext: this.attr(undefined),
      autoplay: this.attr(undefined), // No longer works
      control_time: this.attr(undefined),
      control_scrub: this.attr(undefined),
      control_volume: this.attr(undefined),
      // CAMERA PAGE
      field: this.attr(undefined),
      picture_required: this.attr(undefined),
      capture: this.attr(undefined),
      // DOCUMENT PAGE
      documents: this.attr(undefined)
    }
  }
}

export default FlowPage
