// Returns an object of all schemas added based on the roller addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._roller_id': {
      type: 'number',
      immutable: true,
      name: 'Roller Customer ID'
    },
    'contact._roller_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'Roller: Created At'
    },
    'contact._roller_expires_at': {
      type: 'timestamp',
      name: 'Roller: Expires At'
    },
    'contact._guardian_contact_id': {
      type: '_id',
      name: 'Guardian Contact ID',
      immutable: true
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
