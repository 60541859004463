import capitalize from 'lodash/capitalize'

const tagCase = tag => {
  if (['', null, undefined].includes(tag)) {
    return tag
  }
  return (tag + '')
    .trim()
    .toLowerCase()
    .split(' ')
    .map(word => {
      const capitalizedWord = capitalize(word)
      if (word === capitalizedWord) {
        const parts = word.split(/\b/)
        for (let i = 0; i < parts.length; i++) {
          if (/^[A-Za-z0-9]/.test(parts[i])) {
            parts[i] = capitalize(parts[i])
            break
          }
        }
        return parts.join('')
      }
      return capitalizedWord
    })
    .join(' ')
}

export default tagCase
