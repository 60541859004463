<template>
  <div>
    <calendar-events
      title="Upcoming Events"
      :events="upcomingEvents"
      :loading="loading"
      @load="loadData"
      @edit="editCalendarItem($event)"
      @destroy="destroyCalendarItem($event)"
    />
    <calendar-events
      title="Past Events"
      :events="pastEvents"
      :loading="loading"
      @load="loadData"
      @edit="editCalendarItem($event)"
      @destroy="destroyCalendarItem($event)"
      class="mt-4"
    />
    <div class="text-right">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            primary
            fab
            color="primary"
            class="mt-3 mr-3"
            absolute
            style="bottom: 20px; right: 10px;"
            @click="createCalendarItem"
            :disabled="loading"
          >
            <v-icon>fa fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Create new calendar event</span>
      </v-tooltip>

    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { CalendarItem } from 'ui/models'
import CalendarEvents from './CalendarEvents'
import CalendarItemEditorDialog from 'portal/src/modules/Calendar/CalendarItemEditorDialog'

export default {
  components: {
    CalendarEvents
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    events () {
      return (CalendarItem.all() || []).map(item => {
        let duration
        if (item.start_at && item.end_at) {
          const startTime = moment(item.start_at)
          const endTime = moment(item.end_at)
          const minutes = moment.duration(endTime.diff(startTime)).asMinutes()
          const hours = moment.duration(endTime.diff(startTime)).asHours()
          if (minutes > 0) {
            duration = minutes >= 60
              ? `${hours} hr${hours > 1 ? 's' : ''}`
              : `${minutes} min${minutes > 1 ? 's' : ''}`
          }
        }
        let pastStart
        if (item.start_at) {
          const startAt = moment(item.start_at)
          pastStart = startAt.isBefore(moment.tz(this.$store.getters.timezone))
        }
        return {
          ...item,
          duration,
          pastStart,
          imported: !!item.data?.external_id
        }
      })
    },
    upcomingEvents () {
      return this.events.filter(event => !event.pastStart)
    },
    pastEvents () {
      return this.events.filter(event => event.pastStart)
    }
  },
  watch: {
    loading () {
      this.$emit('updateCount', this.loading ? 'loading' : this.upcomingEvents.length)
    },
    upcomingEvents () {
      this.$emit('updateCount', this.loading ? 'loading' : this.upcomingEvents.length)
    }
  },
  async mounted () {
    await this.loadData()
  },
  methods: {
    async loadData () {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        CalendarItem.deleteAll()
        await CalendarItem.fetchMany({
          params: {
            contact_id: this.contactId
          }
        })
      } finally {
        this.loading = false
      }
    },
    async createCalendarItem () {
      await this.$openWindow({
        component: CalendarItemEditorDialog,
        props: {
          startAt: moment.tz(this.$store.getters.timezone).hour(10).startOf('hour').toISOString(),
          contactId: this.contactId
        }
      })
      await this.loadData()
    },
    async editCalendarItem (event) {
      await this.$openWindow({
        component: CalendarItemEditorDialog,
        props: {
          recordId: event._id
        }
      })
      await this.loadData()
    },
    async destroyCalendarItem (event) {
      if ((await this.$confirm('Are you sure you want to delete this calendar item?')) !== 'Yes') {
        return
      }
      this.loading = true
      try {
        await CalendarItem.destroy(event)
      } finally {
        this.loading = false
      }
      await this.loadData()
    }
  }
}
</script>
