import BaseField from './_BaseField'
import pickSchemasForObject from '../pickSchemasForObject'

function getObjectValuesByFunction ({ value, schema, schemas, options = {} }, func) {
  const { schemaForPath } = require('shared/field-ops')
  if (!value) {
    return
  }
  const result = {}
  Object.entries(value).forEach(([key, entryValue]) => {
    let entrySchema = schemaForPath(schemas || schema.schemas, key)
    if (!entrySchema) {
      if ((schema && schema.strict) || options.strict) {
        return // Ignore schemaless values for strict objects
      } else if (Array.isArray(entryValue)) {
        // TODO handle arrays
      } else if (typeof entryValue === 'object') {
        // Exception for ObjectIDs that just should be unsanitized to strings
        if ((entryValue?._bsontype + '').toUpperCase() === 'OBJECTID') {
          entrySchema = {
            path: key,
            type: '_id'
          }
        } else {
          // If it's an object, give it an object type schema and give it subschemas
          // of deeper schemas starting with the object's path
          entrySchema = schemaForPath({
            ...schemas,
            [key]: { type: 'object' }
          }, key)
        }
      }
    }
    if (entrySchema) {
      let _schemas
      if (entrySchema.type === 'object') {
        _schemas = pickSchemasForObject(schemas, key)
      }
      result[key] = func({
        value: entryValue,
        schema: entrySchema,
        schemas: _schemas,
        options
      })
    } else {
      result[key] = entryValue // Don't require a schema to just pass a value through
    }
  })
  return result
}

class CGObject extends BaseField {
  static sanitize (config) {
    // These need to be added here to prevent a circular reference
    const sanitizeValue = require('../sanitize/sanitizeValue').default
    return getObjectValuesByFunction(config, sanitizeValue)
  }

  static unsanitize (config) {
    // These need to be added here to prevent a circular reference
    const unsanitizeValue = require('../sanitize/unsanitizeValue').default
    return getObjectValuesByFunction(config, unsanitizeValue)
  }
}

export default CGObject
