<template>
  <div style="height: 100%" class="d-flex align-center justify-center">
    <activity
      :contact-id="contactId"
      :activity-list-props="{ showReplyButton: true }"
      ref="activity"
    >
      <template v-slot:footer="{ loading: activityLoading, defaultGatewayId, defaultFrom }">
        <compose-message
          v-if="$restrict({ 'addons.type': 'MESSENGER' }) && (contact.phone || contact.email) && !contact._guardian_contact_id"
          :contact-id="contactId"
          :default-gateway-id="defaultGatewayId"
          :default-from="defaultFrom"
          :disabled="activityLoading"
        />
      </template>
    </activity>
  </div>
</template>

<script>
import Activity from 'ui/modules/Contacts/Activity'
import ComposeMessage from 'portal/src/modules/Messenger/ComposeMessage'
import { Contact } from 'ui/models'

export default {
  components: {
    Activity,
    ComposeMessage
  },
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  computed: {
    contact () {
      return Contact.getFlattened(this.contactId)
    }
  },
  activated () {
    if (this.$refs.activity) {
      this.$refs.activity.scrollToBottom()
    }
  }
}
</script>
