// Returns an object of all schemas added based on the roller addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._smartwaiver_created_at': {
      type: 'timestamp',
      immutable: true,
      name: 'Created At (Smartwaiver)'
    },
    'contact._smartwaiver_expires_at': {
      type: 'timestamp',
      name: 'Expires At (Smartwaiver)'
    },
    'contact._guardian_contact_id': {
      type: '_id',
      name: 'Guardian Contact ID',
      immutable: true
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
