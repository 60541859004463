<template>
  <v-card
    class="contact-editor cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
      height="64"
    >
      <div class="mr-3">
        <contact-avatar
          :contact="context && context.contact"
        />
      </div>
      <v-toolbar-title>{{ friendlyName }}</v-toolbar-title>
      <v-spacer />
      <!--
        This component has its own control over the contact state and will perform
        patch requests on its own.
      -->
      <contact-opt-in-actions
        :contactId="contactId"
        :disabled="loading"
      />
      <help-menu
        path="Contacts"
        prop-label="Contacts"
      />
      <v-btn
        icon
        @click="requestClose"

      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      style="height: calc(100% - 64px);"
      class="pa-0"
    >
      <v-row
        style="height: 100%;"
        class="ma-0"
      >
        <v-col
          v-if="width > breakpointWidth || !(context.contact && context.contact._id)"
          :cols="width > breakpointWidth ? 5 : 12"
          style="height: 100%;"
          class="contact-fields-wrapper"
        >
          <v-card
            style="height: 100%"
          >
            <v-card-text
              class="pa-0"
              style="height: 100%"
            >
              <contact-fields
                :context="editFields ? contextChanged : context"
                :edit="editFields"
                :disabled="loading"
                :loading="loading"
                :canSave="contextChanges.length > 0"
                @edit="editFields = true"
                @save="save"
                @reset="clearChanges(),editFields = false"
                @input="handleChange('context', $event.path, $event.value)"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-if="context.contact && context.contact._id"
          :cols="width > breakpointWidth ? 7 : 12"
          style="height: 100%"
          class="contact-tab-wrapper"
        >
          <v-card class="fill-height">
            <div class="contact-tabs-selection-wrapper">
              <v-tabs
                :value="tabIndex"
                centered
                show-arrows
                :color="$vuetify.theme.dark ? '#ffffff' : '#000000'"
                slider-color="accent"
                slider-size="3"
                ref="tabs"
                height="40"
                @change="tabName = tabs[$event].name"
              >
                <v-tab
                  v-for="(tab, $tabIndex) in tabs"
                  :key="$tabIndex"
                >
                  <v-badge
                    v-if="tab.badge"
                    color="primary"
                    inline
                    :content="tab.badge"
                    offset-x="8"
                  >
                    <span
                      v-if="tab.badge === 'loading'"
                      slot="badge"
                    >
                      <v-progress-circular
                        indeterminate
                        size="12"
                        width="2"
                      />
                    </span>
                    {{ tab.name }}
                  </v-badge>
                  <span v-else>{{ tab.name }}</span>
                </v-tab>
              </v-tabs>
            </div>
            <div class="contact-tab-content">
              <v-card-text
                :class="{ 'pa-0 fill-height': ['Profile', 'Activity'].includes(tab.name) }"
              >
                <contact-fields
                  v-if="tab.name === 'Profile'"
                  :context="editFields ? contextChanged : context"
                  :edit="editFields"
                  :disabled="loading"
                  :loading="loading"
                  :can-save="context && context.contact && contextChanges.length > 0"
                  @edit="editFields = true"
                  @save="save"
                  @reset="clearChanges(),editFields = false"
                  @input="handleChange('context', $event.path, $event.value)"
                />
                <keep-alive>
                  <contact-activity
                    v-if="tab.name === 'Activity'"
                    :contactId="context.contact._id"
                  />
                </keep-alive>
                <contact-minors
                  v-if="tab.name === 'Minors'"
                  :contactId="context.contact._id"
                />
                <contact-loyalty
                  v-if="tab.name === 'Loyalty'"
                  :contactId="context.contact._id"
                />
                <keep-alive>
                  <contact-discount-codes
                    v-if="tab.name === 'Discount Codes'"
                    :contactId="context.contact._id"
                  />
                </keep-alive>
                <!-- Use v-show on some of these to allow for counts from them to be used in the tab bubbles -->
                <contact-offers
                  ref="offersTab"
                  v-if="context.contact && context.contact._id && $restrict({ addons_original: 'OFFERS' })"
                  v-show="tab.name === 'Offers'"
                  :context="context"
                  :context-changed="contextChanged"
                  :all-changes="allChanges"
                  :disabled="loading"
                  @updateCount="offersCount = $event"
                  @changeContext="handleChange('context', $event.path, $event.value)"
                  @clearChanges="clearChanges(),editFields = false"
                  @loadContact="loadContact()"
                />
                <contact-automations
                  ref="automationsTab"
                  v-if="context.contact && context.contact._id && tab.name === 'Automations'"
                  :contactId="context.contact._id"
                />
                <contact-orders
                  v-if="tab.name === 'Orders'"
                  :contactId="context.contact._id"
                />
                <contact-waivers
                  ref="waiversTab"
                  v-if="context.contact && context.contact._id && $restrict({ addons_original: 'WAIVERS' })"
                  v-show="tab.name === 'Waivers'"
                  :contactId="context.contact._id"
                  @updateCount="waiverCount = $event"
                />
                <contact-subscriptions
                  v-if="context.contact && context.contact._id && $restrict({ addons_original: 'SUBSCRIPTIONS' })"
                  v-show="tab.name === 'Subscriptions'"
                  :contactId="context.contact._id"
                />
                <contact-calendar
                  ref="calendarTab"
                  v-if="context.contact && context.contact._id && $restrict({ addons_original: 'CALENDAR' })"
                  v-show="tab.name === 'Calendar'"
                  :contactId="context.contact._id"
                  @updateCount="calendarCount = $event"
                />
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style lang="scss">
.contact-editor {
  height: 100%;
  .container {
    background-color: rgba(128, 128, 128, 0.15);
  }
  .contact-tabs-selection-wrapper {
    border-bottom: 1px solid $lightBorder;
    height: 40px;
    .v-tabs-bar {
      background-color: transparent !important;
    }
    // .v-slide-group__prev, .v-slide-group__next {
    //   display: none !important;
    // }
  }
  .contact-tab-content {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
</style>

<script>
import { Contact } from 'ui/models'
import { ContactAvatar } from 'ui/components/Avatar'
import { getContactFriendlyName } from 'shared/util/friendlyName'
import ContactOptInActions from './ContactOptInActions'
import ContactFields from './Tabs/ContactFields'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import createPreventChangeLostMixin from 'ui/mixins/createPreventChangeLossMixin'
import ContactActivity from './Tabs/ContactActivity'
import ContactCalendar from './Tabs/ContactCalendar'
import ContactLoyalty from './Tabs/ContactLoyalty'
import ContactMinors from './Tabs/ContactMinors'
import ContactOffers from './Tabs/ContactOffers'
import ContactOrders from './Tabs/ContactOrders'
import ContactDiscountCodes from './Tabs/ContactDiscountCodes'
import ContactWaivers from './Tabs/ContactWaivers'
import ContactAutomations from './Tabs/ContactAutomations'
import ContactSubscriptions from './Tabs/ContactSubscriptions'
import { mapGetters } from 'vuex'
import HelpMenu from 'portal/src/components/HelpMenu'

const ContactEditor = {
  oWindow: {
    openCentered: true,
    width: 1200,
    height: 700
  },
  mixins: [
    Contact.mixin({ references: ['context.contact'] }),
    createPreventChangeLostMixin(),
    createChangeTrackerMixin({ path: ['context'] })
  ],
  components: {
    HelpMenu,
    ContactAvatar,
    ContactOptInActions,
    ContactFields,
    ContactActivity,
    ContactCalendar,
    ContactLoyalty,
    ContactMinors,
    ContactOffers,
    ContactOrders,
    ContactDiscountCodes,
    ContactWaivers,
    ContactAutomations,
    ContactSubscriptions
  },
  props: {
    contactId: {
      type: String,
      default: ''
    },
    initialData: {
      type: Object,
      default: () => ({})
    },
    allowedTabs: {
      type: Array,
      default: () => [] // Allow all by default
    },
    restrictedTabs: {
      type: Array,
      default: () => [] // Restrict none by default
    }
  },
  data () {
    return {
      breakpointWidth: 950,
      editFields: false,
      width: null,
      tabName: 'Activity',
      offersCount: null,
      waiverCount: null,
      calendarCount: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    context () {
      return {
        contact: Contact.getFlattened(this.contactId)
      }
    },
    friendlyName () {
      return getContactFriendlyName(this.context.contact)
    },
    tabs () {
      let tabs = [
        { name: 'Activity' },
        ...(!this.context?.contact?._guardian_contact_id && this.$restrict({ $or: [{ 'addons.type': { $in: ['ROLLER', 'SMARTWAIVER'] } }] }) ? [{ name: 'Minors' }] : []),
        ...(this.$restrict({ $or: [{ 'addons.type': { $in: ['SHOPIFY', 'NCRSILVER', 'LOYALTYCASH'] } }, { addons_original: 'LOYALTYCASH' }] }) ? [{ name: 'Loyalty' }] : []),
        ...(this.$restrict({ addons_original: 'OFFERS' }) ? [{ name: 'Offers', badge: this.offersCount }] : []),
        ...(this.$restrict({ 'addons.type': { $in: ['SHOPIFY'] } }) ? [{ name: 'Discount Codes' }] : []),
        ...(this.$hasModule('AUTOMATIONS') ? [{ name: 'Automations' }] : []),
        ...(this.$restrict({ 'addons.type': { $in: ['SHOPIFY'] } }) ? [{ name: 'Orders' }] : []),
        ...(this.$restrict({ addons_original: 'WAIVERS' }) ? [{ name: 'Waivers', badge: this.waiverCount }] : []),
        ...(this.$restrict({ 'addons.type': 'SUBSCRIPTIONS' }) ? [{ name: 'Subscriptions' }] : []),
        ...(this.$restrict({ 'addons.type': 'CALENDAR' }) ? [{ name: 'Calendar', badge: this.calendarCount }] : [])
      ]
      if (this.allowedTabs && this.allowedTabs.length) {
        tabs = tabs.filter(tab => this.allowedTabs.includes(tab.name))
      }
      if (this.restrictedTabs && this.restrictedTabs.length) {
        tabs = tabs.filter(tab => !this.restrictedTabs.includes(tab.name))
      }
      if (this.width <= this.breakpointWidth) {
        tabs = [
          { name: 'Profile' },
          ...tabs
        ]
      }
      return tabs
    },
    tab () {
      return this.tabs.find(tab => tab.name === this.tabName) || this.tabs[0]
    },
    tabIndex () {
      return this.tabs.indexOf(this.tab)
    }
  },
  watch: {
    offersCount () {
      this.onResize() // This helps with the vuetify tabs
    },
    waiverCount () {
      this.onResize() // This helps with the vuetify tabs
    },
    calendarCount () {
      this.onResize() // This helps with the vuetify tabs
    }
  },
  async created () {
    // If there's any initial data props passed in, set them to data
    // This can happen when a user clicks a web push action button that deep
    // links to the contact editor.
    Object.entries(this.initialData || {}).forEach(([key, value]) => {
      if (Object.prototype.hasOwnProperty.call(this.$data, key)) {
        this[key] = value
        if (this.tabName === 'Loyalty') { // Temporary until loyalty tab phased out
          this.tabName = 'Offers'
        }
      }
    })
    // Load the contact
    if (this.contactId && this.contactId.length === 24) {
      await this.loadContact()
    } else {
      this.editFields = true
    }
  },
  async mounted () {
    this.width = this.$el.clientWidth
    if (this.width <= this.breakpointWidth) {
      this.tabName = 'Profile'
    }
  },
  methods: {
    async loadContact () {
      const promise = this.contactsFetchOne(this.contactId, { force: true })
      await this.$manageLoading('loading', promise)
    },
    onResize () {
      if (this.$refs?.tabs?.onResize) {
        this.$refs.tabs.onResize()
      }
      if (this.$el) {
        this.width = this.$el.clientWidth
      }
    },
    async save () {
      if (this.contextChanges.length) {
        this.loading = true
        try {
          const result = await this.contactsSave(this.contextChanged.contact)
          this.loading = false
          this.clearChanges()
          if (!this.contactId) {
            // If this is a created contact, reopen the editor so the contactId prop will be set
            const contactId = result.entities && result.entities.contacts && result.entities.contacts[0] && result.entities.contacts[0]._id
            if (contactId) {
              this.$openWindow({
                component: ContactEditor,
                props: {
                  contactId
                }
              })
              this.$emit('close')
            }
          }
          this.editFields = false
        } catch (e) {
          this.loading = false
          throw e
        }
      }
    }
  }
}

export default ContactEditor
</script>
