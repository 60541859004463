// Returns an object of all schemas added based on the Court Reserve addon

const getSchemas = ({ addon } = {}) => {
  return {
    'contact._courtreserve_id': {
      type: 'number',
      name: 'Court Reserve Customer ID'
    },
    'contact._guardian_contact_id': {
      type: '_id',
      name: 'Guardian Contact ID'
    },
    'contact.last_checkin_type': {
      type: 'text',
      name: 'Last Checkin Type'
    },
    'contact.membership': {
      type: 'text',
      name: 'Membership'
    },
    'contact.membership_started_on': {
      type: 'date',
      name: 'Membership Started On'
    },
    'contact.membership_status': {
      type: 'text',
      name: 'Membership Status'
    },
    'contact.next_event_date': {
      type: 'date',
      name: 'Next Event Date'
    },
    'contact.next_event_type': {
      type: 'text',
      name: 'Next Event Type'
    },
    'contact.next_membership_billing_date': {
      type: 'date',
      name: 'Next Membership Billing Date'
    },
    'contact.next_reservation_date': {
      type: 'date',
      name: 'Next Reservation Date'
    },
    'contact.next_reservation_type': {
      type: 'text',
      name: 'Next Reservation Type'
    },
    'contact.pickleball_rating': {
      type: 'text',
      name: 'Pickleball Rating'
    }
  }
}

export {
  getSchemas
}

export default {
  getSchemas
}
