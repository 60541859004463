<template>
  <div
    class="contact-fields-editor-wrapper"
    :class="{ 'existing-contact': !!(context.contact && context.contact._id) }"
  >
    <div class="contact-fields-editor-action d-flex align-center">
      <div class="px-3 d-flex align-center" style="width: 100%;">
        <div class="flex-grow-1 pl-1">
          <span style="font-size: 16px;">Customer Profile</span>
        </div>
        <v-btn
          v-if="edit && contactExists"
          class="ml-3"
          @click="clearChanges"
          color="warning"
          outlined
          small
          text
          :disabled="loading || disabled"
        >Clear Changes</v-btn>
        <v-btn
          v-if="edit"
          class="ml-3"
          @click="canSave ? $emit('save') : null"
          color="primary"
          :disabled="!canSave || loading"
          v-bind="{ loading }"
          small
        >Save</v-btn>
        <v-btn
          v-if="!edit"
          class="ml-3"
          @click="$emit('edit', true)"
          color="primary"
          :disabled="disabled"
          small
          text
        >Edit</v-btn>
      </div>
    </div>
    <div
      class="contact-fields-editor-scroller pa-3"
      ref="scroller"
    >
      <div
        v-for="schema in displayedSchemas"
        :key="schema.path"
        class="contact-fields-editor-field"
        :class="{'editing mb-3': edit}"
      >
        <label>
          <span v-if="schema.path === 'contact._guardian_contact_id'">Guardian Contact</span>
          <span v-else>{{ schema.name }}</span>
          <v-btn
            v-if="schema.value_by_account"
            icon
            x-small
            class="pa-1"
            style="margin-top: -2px;"
            @click="viewValueByAccount(schema)"
          >
            <v-icon
              x-small
            >fas fa-external-link-alt</v-icon>
          </v-btn>
        </label>
        <div
          v-if="schema.path === 'contact._guardian_contact_id'"
          class="flex-grow-1"
        >
          <contact-ref
            style="margin-top: -7px"
            :record-id="context.contact._guardian_contact_id"
            :contact-editor="contactEditor"
          />
        </div>
        <field-ui
          v-else-if="edit"
          class="mb-0 flex-grow-1"
          style="width: 50%"
          :value="get(context, schema.path)"
          :schema="{
            ...schema,
            ...(schema.type === 'checkbox' ? { input_type: 'select' } : {}),
            ...(schema.type === 'date' ? { input_type: 'dropdown' } : {})
          }"
          :label="null"
          :disabled="disabled || schema.immutable"
          :placeholder="schema.placeholder"
          hide-details
          dense
          outlined
          @input="$emit('input', { path: schema.path, value: $event })"
        />
        <div
          class="static-value flex-grow-1"
          v-else
        >
          <div
            v-if="schema && schema.type === 'image'"
            style="max-width: 75px;"
          >
            <preview-by-filename
              :key="get(context, schema.path) || schema.path"
              :value="fieldValue(get(context, schema.path), schema, { timezone: $timezone })"
              prefer-thumb
              rounded
              :size="35"
            />
          </div>
          <div
            v-else-if="schema && schema.type === 'object'"
          >
            <v-container
              v-for="(row, $index) in (fieldValue(get(context, schema.path), schema, { timezone: $timezone, returnObject: true }) || [])"
              :key="$index"
              class="mt-1 mb-5 pa-0"
              style="background-color: transparent;"
            >
              <v-row
                v-for="(field, $index) in (row || [])"
                :key="$index"
                dense
                style="margin-bottom: -13px;"
              >
                <v-col class="contact-field-object-label">{{ field.label }}</v-col>
                <v-col>{{ field.value }}</v-col>
              </v-row>
            </v-container>
          </div>
          <div
            v-else
          >{{ fieldValue(get(context, schema.path), schema, { timezone: $timezone }) }}</div>
        </div>
      </div>
      <div
        v-if="edit"
        class="contact-fields-editor-field"
      >
        <label>&nbsp;</label>
        <v-autocomplete
          :value="addFieldPath"
          outlined
          dense
          placeholder="Add a field..."
          :items="addFieldOptions"
          @change="addField"
          auto-select-first
          no-data-text="All available fields selected"
        />
      </div>
    </div>
    <div
      v-if="context.contact && context.contact._id"
      class="contact-indicators-wrapper"
    >
      <contact-indicators
        :contact-id="context.contact._id"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact-fields-editor-wrapper {
  height: 100%;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  overflow-y: auto;

  .contact-fields-editor-action {
    height: 50px;
    width: 100%;
  }
  .contact-fields-editor-scroller {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex: 1;
    will-change: transform;
    overflow-y: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;

    .contact-fields-editor-field {
      display: flex;
      margin-bottom: 15px;
      label {
        width: 35%;
        padding-right: 20px;
        opacity: 0.5;
        text-align: right;
        font-weight: 300;
      }
      > div {
        width: 65%;
      }
      .static-value {
        font-weight: 400;
      }
      &.editing {
        label {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: -4px;
          margin-bottom: 0;
        }
      }
    }
  }
  .contact-indicators-wrapper {
    border-top: 1px $lightBorder solid;
    width: 100%;
  }
  .contact-field-object-label {
    text-align: right;
    opacity: 0.5;
    text-align: right;
    font-weight: 300;
  }
}
</style>

<script>
import FieldUi from 'ui/components/Field'
import { fieldValue } from 'shared/field'
import { mapGetters } from 'vuex'
import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import ContactIndicators from './ContactIndicators'
import PreviewByFilename from 'ui/pickers/FilePicker/PreviewByFilename'
import ValuesByAccountDialog from './ValuesByAccountDialog.vue'
import ContactRef from 'ui/references/Contact'
import ContactEditor from '../../'

const blacklistPaths = [
  'contact._files',
  // '_id',
  'contact._last_account_id',
  'contact._notes',
  'contact.account_id',
  'contact.channel',
  'contact.checkins',
  'contact.claims',
  'contact.created_at',
  'contact.groups',
  'contact.last_checkin_at',
  'contact.last_message_at',
  'contact.redemptions',
  'contact.updated_at',
  'contact._subscriptions'
  // 'contact._sms0',
  // 'contact._sms1',
  // 'contact.sms_on',
  // 'contact.email_on',
  // 'contact.sms_bounce',
  // 'contact.email_bounce'
]

export default {
  components: {
    FieldUi,
    ContactIndicators,
    PreviewByFilename,
    ContactRef
  },
  props: {
    context: {
      type: Object,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    },
    canSave: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      addFieldPath: null,
      addedFieldPaths: [],
      expandedFields: []
    }
  },
  computed: {
    contactEditor () {
      return ContactEditor
    },
    contactExists () {
      return !!this.context?.contact?._id
    },
    ...mapGetters({
      schemas: 'schemas',
      contactSchemas: 'filterableContactSchemas'
    }),
    /**
     * Display the 'displayedPaths' at the very top and then alphabetize all other fields below.
     * Keep the fields added during this session at the very bottom
     */
    displayedSchemas () {
      const displayedPaths = [
        'contact.first_name',
        'contact.last_name',
        'contact.phone',
        'contact.email',
        ...(this.context?.contact?._guardian_contact_id ? ['contact._guardian_contact_id'] : []),
        'contact.groups',
        'contact.tags',
        'contact.sms_on',
        'contact.email_on'
      ]

      const schemasForValuesByAccount = Object.values(this.contactSchemas).filter(schema => !!schema.value_by_account)
      schemasForValuesByAccount.forEach(schema => displayedPaths.push(schema.path))

      // List of schemas that have field values and aren't part of displayedPaths
      let schemasWithValues = []
      for (const path of Object.keys(this.context?.contact || {})) {
        const contactPath = 'contact.' + path
        if (
          displayedPaths.includes(contactPath) ||
          blacklistPaths.includes(contactPath) ||
          this.addedFieldPaths.includes(contactPath)
        ) {
          continue
        }
        const schema = this.contactSchemas['contact.' + path]
        if (schema) {
          schemasWithValues.push(schema)
        }
      }

      schemasWithValues = orderBy(schemasWithValues, o => ((o.name || '') + '').toUpperCase())

      const displayedSchemas = displayedPaths
        .map(path => this.contactSchemas[path])

      const addedSchemas = this.addedFieldPaths.map(path => this.contactSchemas[path])

      return displayedSchemas
        .concat(schemasWithValues)
        .concat(addedSchemas)
        .filter(schema => !this.edit || !schema.immutable) // Remove immutables in edit mode
        .map(schema => {
          // If no groups exist for account.
          if (schema.path === 'contact.groups' && (!schema.options || !schema.options.length)) {
            return {
              ...schema,
              ...({
                immutable: true, // To disable input field.
                multiple: false, // To prevent loop through empty values array.
                placeholder: 'No Groups Exist for Account'
              })
            }
          } else if (schema.type === 'object') {
            return {
              ...schema,
              schemas: Object.entries(this.contactSchemas)
                .filter(([path]) => path.startsWith(schema.path))
                .map(([path, _schema]) => {
                  return _schema
                })
            }
          } else {
            return schema
          }
        })
    },
    addFieldOptions () {
      let withoutDisplayed = pickBy(this.contactSchemas, (schema) => !this.displayedSchemas.includes(schema))

      withoutDisplayed = Object.entries(withoutDisplayed).filter(([path, schema]) => {
        return !schema.immutable && !blacklistPaths.includes(path)
      })

      withoutDisplayed = orderBy(withoutDisplayed, ([path, schema]) => ((schema.name || '') + '').toUpperCase())

      return withoutDisplayed.map(([path, schema]) => ({
        text: schema.name,
        value: path
      }))
    }
  },
  watch: {
    edit () {
      this.addedFieldPaths = []
    }
  },
  methods: {
    get,
    fieldValue,
    async addField (path) {
      this.addedFieldPaths.push(path)
      await this.$nextTick()
      // Scroll to bottom
      if (this.$refs.scroller) {
        this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight
      }
      this.addFieldPath = null
    },
    async clearChanges () {
      if (this.canSave) {
        const result = await this.$confirm('Are you sure you want to clear your changes? They will be lost.', {
          title: 'Clear all changes?',
          overlay: true,
          buttons: [{
            name: 'Discard Changes',
            value: 'yes',
            color: 'warning',
            outlined: true
          }, {
            name: 'Continue Editing',
            value: 'no',
            color: 'primary'
          }]
        })
        if (result !== 'yes') {
          return
        }
      }
      this.$emit('reset')
    },
    viewValueByAccount (schema) {
      this.$openWindow({
        component: ValuesByAccountDialog,
        props: {
          contactId: this.context?.contact?._id,
          schema
        }
      })
    }
  }
}
</script>
