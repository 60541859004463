<template>
  <v-card
    class="cg-editor calendar-item-editor with-footer"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Calendar Event</v-toolbar-title>

      <v-spacer />

      <v-chip
        v-if="record.status"
        :color="{ DONE: 'success', STARTED: 'warning', READY: 'info' }[record.status] || 'default'"
      >{{ record.status }}</v-chip>

      <v-btn
        icon
        @click="requestClose"
        :disabled="loading"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      fluid
      class="pa-4"
    >
      <v-alert
        v-if="imported"
        type="warning"
        :color="$vuetify.theme.currentTheme.warning"
        class="pb-3"
      >
        This calendar event cannot be modified because it was created outside Patch.
      </v-alert>
      <v-text-field
        label="Title"
        :value="recordChanged.title"
        @input="$handleChange('record', 'title', $event)"
        dense
        outlined
        autofocus
        hide-details
        class="mb-4"
        :disabled="loading || imported"
      />

      <div
        v-if="recordChanged.contact_id"
        class="mb-1"
      >
        <v-chip
          color="success"
          :close="!record._id"
          small
          :disabled="loading || imported"
          @click:close="$handleChange('record', 'contact_id', null)"
        >
          <contact-ref
            :record-id="recordChanged.contact_id"
          />
        </v-chip>
      </div>

      <contact-picker
        v-else
        label="Contact To Trigger Event For"
        :value="recordChanged.contact_id"
        @input="$handleChange('record', 'contact_id', $event)"
        outlined
        dense
        :disabled="loading || imported"
      />

      <v-row
        class="py-5"
      >
        <v-col>
          <field-date
            outlined
            dense
            :schema="{ type: 'timestamp', input_type: 'calendar', timezone: $store.getters.timezone }"
            :value="recordChanged.start_at"
            label="Start Time"
            :disabled="loading || imported"
            hide-details
            @input="$handleChange('record', 'start_at', $event)"
          />
        </v-col>
        <v-col>
          <div
            class="d-flex"
          >
            <field-date
              outlined
              dense
              :schema="{ type: 'timestamp', input_type: 'calendar', timezone: $store.getters.timezone }"
              :value="recordChanged.end_at"
              label="End Time (optional)"
              :disabled="loading || imported"
              hide-details
              @input="$handleChange('record', 'end_at', $event)"
            />
            <v-btn
              v-if="recordChanged.end_at && !loading && !imported"
              icon
              @click="clearEndAt"
            >
              <v-icon>fa-solid fa-times</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <field-ui
        outlined
        dense
        :schema="{ type: 'text', multiple: true }"
        :value="recordChanged.tags"
        label="Tags"
        :disabled="loading || imported"
        hide-details
        @input="$handleChange('record', 'tags', $event)"
      />
      <br />
      <v-textarea
        label="Data"
        outlined
        dense
        rows="4"
        :disabled="loading || imported"
        :value="recordChanged.data ? JSON.stringify(recordChanged.data) : '{\n}'"
        @input="handleDataInput"
        :error-messages="dataErrorMessages"
      />
    </v-container>
    <v-footer
      class="d-flex py-4"
    >
      <v-btn
        v-if="record._id"
        color="warning"
        @click="destroy"
        :disabled="loading || imported"
      >Delete</v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="requestClose()"
        outlined
        :disabled="loading"
      >Cancel</v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        @click="save"
        :disabled="!valid || imported"
        :loading="loading"
      >Save &amp; Close</v-btn>
    </v-footer>
  </v-card>
</template>

<style lang="scss">
</style>

<script>
import { CalendarItem } from 'ui/models'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import createPreventChangeLossMixin from 'ui/mixins/createPreventChangeLossMixin'
import FieldDate from 'ui/components/Field/primitives/FieldDate'
import FieldUi from 'ui/components/Field'
import ContactPicker from 'ui/pickers/ContactPicker'
import ContactRef from 'ui/references/Contact'

export default {
  oWindow: {
    overlay: true,
    persistent: true,
    width: 750,
    height: 700
  },
  components: {
    ContactPicker,
    ContactRef,
    FieldDate,
    FieldUi
  },
  mixins: [
    createPreventChangeLossMixin(),
    createChangeTrackerMixin({ path: ['record'] })
  ],
  props: {
    recordId: {
      type: String,
      default: null
    },
    startAt: {
      type: String,
      default: null
    },
    contactId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      currentData: null
    }
  },
  computed: {
    record () {
      return CalendarItem.find(this.recordId) || { start_at: this.startAt }
    },
    dataErrorMessages () {
      try {
        JSON.parse(this.currentData)
        return null
      } catch (e) {
        return ['JSON is invalid. Make sure to use double quotes.']
      }
    },
    valid () {
      return !!this.recordChanged.start_at && !!this.recordChanged.contact_id && !this.dataErrorMessages?.length
    },
    imported () {
      return !!this.record.data?.external_id
    }
  },
  methods: {
    clearEndAt () {
      this.$handleChange('record', 'end_at', null)
    },
    handleDataInput ($event) {
      this.currentData = $event
      try {
        this.$handleChange('record', 'data', JSON.parse($event))
      } catch (e) {
      }
    },
    async loadData () {
      this.loading = true
      try {
        if (this.recordId) {
          await CalendarItem.fetchOne(this.recordId, { force: true })
        } else if (this.contactId) {
          this.$handleChange('record', 'contact_id', this.contactId)
        }
      } finally {
        this.loading = false
      }
    },
    async save () {
      if (this.loading || this.imported) {
        return
      }
      this.loading = true
      try {
        await CalendarItem.save(this.recordChanged)
        this.$clearChanges()
        this.$emit('close')
      } finally {
        this.loading = false
      }
    },
    async destroy () {
      if (this.loading || this.imported) {
        return
      }
      if ((await this.$confirm('Are you sure you want to delete this calendar item?')) !== 'Yes') {
        return
      }
      this.loading = true
      try {
        await CalendarItem.destroy(this.record)
        this.$emit('close')
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.loadData()
  }
}
</script>
